const { selectAllTags } = require('./helpers');
const moment = require('moment');

/**
 * The purpose of this module is to get data from contentful
 */
const { createClient } = require('contentful');
const logger = require('./logger');

// const space = process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID;
// const accessToken = process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN;
// const accessTokenPreview = process.env.NEXT_PREVIEW_CONTENTFUL_ACCESS_TOKEN;

let deliveryClient = null;
let previewClient = null;

/**
 * Initialize the contentful Client
 * @param options {spaceId: string, deliveryToken: string, previewToken: string}
 *
 * @returns {undefined}
 */
const initClients = (options) => {
	// Getting the app version
	const { version } = require('../package.json');
	const applicationName = `myblog-contentfull-/${version}`;

	const config = options || {
		spaceId: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
		deliveryToken: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
		previewToken: process.env.NEXT_PREVIEW_CONTENTFUL_ACCESS_TOKEN
	};

	deliveryClient = createClient({
		application: applicationName,
		space: config.spaceId,
		accessToken: config.deliveryToken,
		// Environment variable is used here to enable testing this app internally at Contentful.
		// You can just omit the host since it defaults to 'cdn.contentful.com'
		// host: process.env.CONTENTFUL_DELIVERY_API_HOST,
		removeUnresolved: true
	});

	// todo fix the token cache issue
	previewClient = deliveryClient;
	// previewClient = createClient({
	// 	application: applicationName,
	// 	space: config.spaceId,
	// 	accessToken: config.previewToken,
	// 	// Environment variable is used here to enable testing this app internally at Contentful.
	// 	// You should use 'preview.contentful.com' as host to use the preview api
	// 	// host: process.env.CONTENTFUL_PREVIEW_API_HOST,
	// 	removeUnresolved: true
	// });
};

module.exports.initClients = initClients;
initClients();
/**
 * Get the Space the app is connected to. Used for the settings form and to get all available locales
 * @param api - string - the api to use, cda or cap. Default: 'cda'
 * @returns {undefined}
 */
module.exports.getSpace = throwOnEmptyResult('Space', (api = 'cda') => {
	return getClient(api)
		.getSpace()
		.then((response) => {
			return response;
		})
		.catch((err) => logger.log(err));
});

module.exports.getSpaces = throwOnEmptyResult('Spaces', (api = 'cda') => {
	return getClient(api).getSpaces();
});

/**
 * Get the environment locales
 * @param api - string - the api to use, cda or cap. Default: 'cda'
 * @returns {undefined}
 */
module.exports.getLocales = throwOnEmptyResult('Environment', (api = 'cda') => {
	return getClient(api)
		.getLocales()
		.then((response) => response.items);
});

/**
 * Gets an entry. Used to detect the `Draft` or `Pending Changes` state
 * @param entryId - string - the entry id
 * @param api - string - the api to use fetching the entry
 *
 * @returns {Object}
 */

module.exports.getEntry = throwOnEmptyResult('Entry', (entryId, contentType, api = 'cda') => {
	return getClient(api)
		.getEntries({ content_type: contentType, 'sys.id': entryId })
		.then((response) => response.items[0]);
});

/**
 * Get all entries with content_type `BlogPost`
 * @param locale - string - the locale of the entry [default: 'en-US']
 * @param api - string the api enpoint to use when fetching the data
 * @returns {Array<Object>}
 */
module.exports.getArticles = throwOnEmptyResult(
	'Blog Posts',
	(
		{ skip, limit, tags, publishDate = moment().format() } = {},
		locale = 'en-US',
		api = 'cda'
	) => {
		let query = {
			content_type: 'blogPost',
			locale,
			limit,
			skip,
			'fields.publishDate[lte]': publishDate,
			order: '-sys.createdAt', // Ordering the entries by creation date
			include: 1 // We use include param to increase the link level, the include value goes from 1 to 6
		};
		if (tags) {
			query['fields.tags'] = tags;
		}

		return getClient(api)
			.getEntries(query)
			.then((response) => response.items);
	}
);

/**
 * Get all entries with content_type `BlogPost`
 * @param locale - string - the locale of the entry [default: 'en-US']
 * @param api - string the api enpoint to use when fetching the data
 * @returns {Array<Object>}
 */
module.exports.getArticlesCount = throwOnEmptyResult(
	'Blog Posts Count',
	({ tag = '' } = {}, locale = 'en-US', api = 'cda') => {
		let query = {
			content_type: 'blogPost',
			locale
		};
		if (tag) {
			query['fields.tags'] = tag;
		}

		return getClient(api)
			.getEntries(query)
			.then((response) => (response.items ? response.items.length : 0));
	}
);

/**
 * Get all hightlited entries with content_type `BlogPost`
 * @param options - object - with skip and limit params
 * @param locale - string - the locale of the entry [default: 'en-US']
 * @param api - string the api enpoint to use when fetching the data
 * @returns {Array<Object>}
 */
module.exports.getHighlightedArticles = throwOnEmptyResult(
	'Highlighted Aritcles',
	({ skip = '0', limit = '5' } = {}, locale = 'en-US', api = 'cda') => {
		let query = {
			content_type: 'blogPost',
			skip,
			limit,
			'fields.isHighlight': true,
			locale,
			order: '-sys.createdAt' // Ordering the entries by creation date
		};

		return getClient(api)
			.getEntries(query)
			.then((response) => response.items);
	}
);

/**
 * Get One`BlogPost` by slug
 * @param locale - string - the locale of the entry [default: 'en-US']
 * @param api - string the api enpoint to use when fetching the data
 * @returns {Object}
 */
module.exports.getArticleBySlug = throwOnEmptyResult(
	'Blog Posts',
	({ slug, preview = false } = {}, locale = 'en-US', api = 'cda') => {
		let params = {
			content_type: 'blogPost',
			'fields.slug': slug
		};
		if (!preview) {
			params['fields.publishDate[lte]'] = moment().format();
		}

		return getClient(api)
			.getEntries(params)
			.then((response) => (response.items.length > 0 ? response.items[0] : {}));
	}
);

/**
 * Get all entries with content_type `course`
 * @param locale - string - the locale of the entry [default: 'en-US']
 * @param api - string the api enpoint to use when fetching the data
 * @returns {Array<Object>}
 */
module.exports.getAllTags = throwOnEmptyResult('Blog Posts', (locale = 'en-US', api = 'cda') => {
	return getClient(api)
		.getEntries({ content_type: 'blogPost', locale })
		.then((response) => selectAllTags(response.items));
});

/**
 * Get all entries with content_type `course`
 * @param locale - string - the locale of the entry [default: 'en-US']
 * @param api - string the api enpoint to use when fetching the data
 * @returns {Array<Object>}
 */
module.exports.getPortfolios = throwOnEmptyResult('Portfolios', (locale = 'en-US', api = 'cda') => {
	return getClient(api)
		.getEntries({
			content_type: 'portfolio',
			locale,
			order: '-sys.createdAt', // Ordering the entries by creation date
			include: 1 // We use include param to increase the link level, the include value goes from 1 to 6
		})
		.then((response) => response.items);
});

/**
 * Get One`BlogPost` by slug
 * @param locale - string - the locale of the entry [default: 'en-US']
 * @param api - string the api enpoint to use when fetching the data
 * @returns {Object}
 */
module.exports.getPortfolioBySlug = throwOnEmptyResult(
	'Portfolio by slug',
	({ slug, preview = false } = {}, locale = 'en-US', api = 'cda') => {
		let params = {
			content_type: 'portfolio',
			'fields.slug': slug,
			order: '-sys.createdAt' // Ordering the entries by creation date
		};

		return getClient(api)
			.getEntries(params)
			.then((response) => (response.items.length > 0 ? response.items[0] : {}));
	}
);

/**
 * Get all entries with content_type `course`
 * @param locale - string - the locale of the entry [default: 'en-US']
 * @param api - string the api enpoint to use when fetching the data
 * @returns {Array<Object>}
 */
module.exports.getAdminPerson = throwOnEmptyResult('Person', (locale = 'en-US', api = 'cda') => {
	return getClient(api)
		.getEntries({
			content_type: 'person',
			locale,
			'fields.isAdmin': true,
			order: '-sys.createdAt', // Ordering the entries by creation date
			include: 1 // We use include param to increase the link level, the include value goes from 1 to 6
		})
		.then((response) => response.items[0].fields);
});

/**
 * Get entries of content_type `layout` e.g. Landing page
 * @param slug - string - the slug of the entry to use in the query
 * @param locale - string - locale of the entry to request [default: 'en-US']
 * @param api - string - the api enpoint to use when fetching the data
 * @returns {Object}
 */
module.exports.getLandingPage = (slug, locale = 'en-US', api = 'cda') => {
	// Even though we need a single entry, we request it using the collection endpoint
	// To get all the linked refs in one go, the SDK will use the data and resolve the links automatically
	return getClient(api)
		.getEntries({
			content_type: 'layout',
			locale,
			'fields.slug': slug,
			include: 3
		})
		.then((response) => response.items[0]);
};

/**
 * Get an entry with content_type `course`
 * @param slug - string - the slug of the entry to use in the query
 * @param locale - string - locale of the entry to request [default: 'en-US']
 * @param api - string - the api enpoint to use when fetching the data
 * @returns {Object}
 */
module.exports.getCourse = throwOnEmptyResult('Course', (slug, locale = 'en-US', api = 'cda') => {
	// Even though we need a single entry, we request it using the collection endpoint
	// To get all the linked refs in one go, the SDK will use the data and resolve the links automatically
	return getClient(api)
		.getEntries({
			content_type: 'course',
			'fields.slug': slug,
			locale,
			include: 2
		})
		.then((response) => response.items[0]);
});

module.exports.getCategories = throwOnEmptyResult('Course', (locale = 'en-US', api = 'cda') => {
	return getClient(api)
		.getEntries({ content_type: 'category', locale })
		.then((response) => response.items);
});

/**
 * Get Courses by Categories
 * To get a course by category, simply query all entries
 * with a query params `fields.categories.sys.id` equal to the desired category id
 * Note that you need to send the `content_type` param to be able to query the entry
 * @param category - string - the id of the category
 * @param locale - string - locale of the entry to request [default: 'en-US']
 * @param api - string - the api enpoint to use when fetching the data
 * @returns {Object}
 */
module.exports.getCoursesByCategory = throwOnEmptyResult(
	'Category',
	(category, locale = 'en-US', api = 'cda') => {
		return getClient(api)
			.getEntries({
				content_type: 'course',
				'fields.categories.sys.id': category,
				locale,
				order: '-sys.createdAt',
				include: 1
			})
			.then((response) => response.items);
	}
);

// Utility function
function getClient(api = 'cda') {
	return api === 'cda' ? deliveryClient : previewClient;
}

/**
 * Utility function for wrapping regular API calls.
 * This is done for easily catching 404 errors.
 * @param  {string}   context The type of result the function is looking for
 * @param  {Function} fn      The function to wrap
 * @return {Object}           The result of `fn`, if not empty
 * @throws {Error}    When `fn` returns an empty result
 */
function throwOnEmptyResult(context, fn) {
	return function (...params) {
		return fn(...params)
			.then((data) => {
				if (!data) {
					var err = new Error(`${context} Not Found`);
					err.status = 404;
					throw err;
				}
				return data;
			})
			.catch((err) => {
				console.log(err);
			});
	};
}
